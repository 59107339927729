import React from 'react'

import useWindowDimensions from '../hooks/useWindowDimensions.js'
import makeStyles from '@mui/styles/makeStyles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import resume from '../assets/resume.pdf'

// region styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justfyContent: 'stretch',
    alignContent: 'stretch',
    alignItems: 'stretch',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    color: '#FFFFFF',
  },
  middle: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
  },
  appbar: { minHeight: 100, position: 'static', color: 'primary' },
  secondaryButtonBackDrop: {
    backgroundColor: 'rgba(49, 49,49, 0)',
    width: 'fit-content',
    borderRadius: '4px',
  },
  mainButtonStyle: {
    maxHeight: '40px',
  },
}))

export default function Navbar({ onTabChange }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { width } = useWindowDimensions()

  const handleChange = (event, newValue) => {
    if (onTabChange) {
      onTabChange(newValue)
    }

    setValue(newValue)
  }

  React.useEffect(() => {
    console.log(width)
  }, [width])

  return (
    <AppBar className={classes.appbar} data-testid="navbar" color="primary">
      <Toolbar>
        <div className={classes.root}>
          <div className={classes.left} data-testid="page-menu">
            <Tabs
              textColor="inherit"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="WORK" />
              <Tab label="ABOUT" />
            </Tabs>
          </div>
          {width > 620 && (
            <div className={classes.middle} data-testid="logo">
              <Typography style={{ fontFamily: ['Inspiration'], fontSize: 48 }}>
                She Is Digital
              </Typography>
            </div>
          )}
          <div className={classes.right} data-testid="page-links">
            <div className={classes.secondaryButtonBackDrop}>
              <a
                href={resume}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <IconButton className={classes.mainButtonStyle} size="large" color="secondary">
                  <DownloadIcon size="large" color="secondary" />
                </IconButton>
              </a>

              <a
                href="https://www.linkedin.com/in/sheisdigital"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <IconButton className={classes.mainButtonStyle} size="large" color="secondary">
                  <LinkedInIcon size="large" color="secondary" />
                </IconButton>
              </a>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}
