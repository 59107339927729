import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'

// region styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  },
  chipStyle: { borderRadius: '2px' },
  skillGroup: {
    margin: '24px',
    height: 'fit-content',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    flexGrow: 1,
  },
  chipList: {
    borderRadius: '8px',
    //borderThickness: '4px',
    //borderColor: '#FFCC00',
    // backgroundColor: '#FFCC00',
    padding: '12px',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    //width: '100%',
    height: 'fit-content',
    flexFlow: 'row wrap',
  },
}))

const skillsGroup = [
  'React',
  'Material UI',
  'Javascript',
  'C#',
  '.NET',
  'Node.js,',
  'Unity3D',
  'RESTful APIs',
  'UX',
  'Agile',
  'git',
  'svn',
]

const focusGroup = [
  'Training Platforms',
  'Cybersecurity Simulation Software',
  'Serious & Casual Games',
  'Design Process',
  'Building Teams',
]

const tools = [
  'Visual Studio',
  'WebStorm',
  'Unity3D',
  'JIRA',
  'Confluence',
  'Bitbucket',
  'Gitlab',
  'Jenkins',
  'Swagger',
  'PGAdmin',
]

const familiar = [
  'Typescript',
  'mySQL',
  'Java',
  'Docker',
  'AWS',
  'xAPI',
  'Oculus VR',
  'xCode',
  'iOS',
  'Android',
  'linux',
  'Objective-C',
]

//REFconst legacy = ['Flash Actionscript', 'Flash Media Server', 'Multiedia Lingo']

export default function Skills() {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="navbar">
      <div className={classes.skillGroup}>
        <Typography align="left" variant="h6" color="primary">
          Skills
        </Typography>

        <div className={classes.chipList}>
          <Chip className={classes.chipStyle} label="Recent" color="primary" variant="contained" />
          {skillsGroup.map((skill, index) => (
            <Chip
              key={index}
              className={classes.chipStyle}
              label={skill}
              color="primary"
              variant="outlined"
            />
          ))}

          <Chip className={classes.chipStyle} label="Focus" color="primary" variant="contained" />
          {focusGroup.map((skill, index) => (
            <Chip
              key={index}
              className={classes.chipStyle}
              label={skill}
              color="primary"
              variant="outlined"
            />
          ))}
          <Chip className={classes.chipStyle} label="Tools" color="primary" variant="contained" />
          {tools.map((skill, index) => (
            <Chip
              key={index}
              className={classes.chipStyle}
              label={skill}
              color="primary"
              variant="outlined"
            />
          ))}
          <Chip
            className={classes.chipStyle}
            label="Worked With"
            color="primary"
            variant="contained"
          />
          {familiar.map((skill, index) => (
            <Chip key={index} className={classes.chipStyle} label={skill} variant="outlined" />
          ))}
        </div>
      </div>
    </div>
  )
}
