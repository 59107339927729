import React from 'react'

// Material UI
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'

import About from './views/About.js'
import Footer from './views/Footer.js'
import NavBar from './views/NavBar.js'
import Portfolio from './views/Portfolio.js'
import Welcome from './views/Welcome.js'
import Skills from './views/Skills.js'
import { lightTheme } from './styles/muiTheme.js'
import './App.css'

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    display: 'block',
    position: 'sticky',
    paddingTop: '100px',
  },
  pageContent: {
    height: 'fit-content',
    display: 'block',
    position: 'sticky',
    top: 100,
  },
}))

function App() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const onTabChange = (index) => {
    console.log('onTabChange' + index)
    setValue(index)
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div className="App" data-testid="app-root">
        <NavBar onTabChange={onTabChange} />
        <Box className={classes.content}>
          <Paper className={classes.pageContent}>
            {value === 0 ? <Welcome /> : <About />}

            <Skills />
            <Portfolio />
            <Footer />
          </Paper>
        </Box>
      </div>
    </ThemeProvider>
  )
}

export default App
