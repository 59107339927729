import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'

//Images
import slamr from '../assets/slamr.png'
import artemis from '../assets/artemis.png'
import lms from '../assets/summits-concept.png'
import summits from '../assets/summits.png'
import psdetective from '../assets/psdetective.png'
import ares from '../assets/ares.jpg'
import ares_minigames from '../assets/ares_minigames.png'
import sciops from '../assets/sciops.png'
import mahjongdragon from '../assets/mahjongdragon.jpg'
import kites from '../assets/kites.png'

//https://www.youtube.com/watch?v=ERTBYSSwYrI

const imageWidth = 420
const imageHeight = 240

// region styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: '24px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '12px',
  },
  buttonStyle: {},
  imageList: {
    borderRadius: '8px',
    backgroundColor: '#313131',
    padding: '12px',
    display: 'flex',
    flexDirection: 'row',
    gap: '18px',
    height: 'fit-content',
    flexFlow: 'row wrap',
  },
}))

const itemData = [
  {
    img: slamr,
    title: 'Cyber Range Software',
    width: 427,
    company: ' © By Light',
  },
  {
    img: summits,
    title: 'Knowledge Assessment',
    width: 427,
    company: ' © By Light',
  },
  {
    img: artemis,
    title: 'User Portal',
    width: 427,
    company: ' © By Light',
  },
  {
    img: psdetective,
    title: 'Powershell Technical Game',
    width: 427,
    company: ' © By Light',
  },
  {
    img: lms,
    title: 'Gamified LMS Concept',
    width: 427,
    company: ' © By Light',
  },
  {
    img: ares,
    title: 'Cybersecurity Training Platform',
    width: 427,
    company: ' © Circadence',
  },
  {
    img: ares_minigames,
    title: 'Technical Mini Games',
    width: 427,
    company: ' © Circadence',
  },
  {
    img: sciops,
    title: 'Serious Game for K12',
    width: 427,
    company: ' © Plasma Games',
  },
  {
    img: mahjongdragon,
    title: 'Casual Facebook Game',
    width: 427,
    company: ' © MTV Networks',
  },
  {
    img: kites,
    title: 'Casual Web Game',
    width: 427,
    company: ' © Shockwave Games',
  },
]

export default function Portfolio() {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="navbar">
      <Typography align="left" variant="h6" color="primary">
        Projects
      </Typography>
      <div className={classes.imageList}>
        {itemData.map((item) => (
          <ImageListItem key={item.img} sx={{ width: imageWidth, imageHeight: imageHeight }}>
            <img
              src={`${item.img}?h=${imageHeight}&fit=crop&auto=format`}
              srcSet={`${item.img}?h=${imageHeight}&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar  title={item.title} subtitle={item.company} />
          </ImageListItem>
        ))}
      </div>
    </div>
  )
}
