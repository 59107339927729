import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { mailToStr } from './constants.js'

// region styles
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '24px',
    backgroundColor: '#313131',
    //gap: '12px',
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingBottom: '12px',
  },
  buttonStyle: {
    maxWidth: 'fit-content',
    //maxHeight: '40px',
    borderThickness: '4px',
  },
}))

export default function About() {
  const classes = useStyles()
  const onConnect = () => {
    window.open(mailToStr)
  }
  return (
    <div className={classes.root} data-testid="welcome">
      <Typography
        color="secondary"
        style={{ fontFamily: ['Roboto'], fontSize: 24, padding: '24px 20px 12px 20px' }}
      >
        Passion for developing intuitive products
      </Typography>
      <Typography
        style={{
          color: '#FFFFFF',
          fontFamily: ['Roboto'],
          fontSize: 18,
          padding: '0px 20px 12px 20px',
          maxWidth: '1024px',
        }}
      >
        I started my career over 15 years ago, helping companies leverage emerging design &
        technology. I develop cross-platform products with unique and immersive user experiences in
        the educational, training, and casual user spaces. Versatiliy is one of my strengths. I
        thrive (as developer or lead) in cross-disciplined teams delivering products to users.
      </Typography>{' '}
      <div />
      <Button
        className={classes.buttonStyle}
        color="secondary"
        size="large"
        variant="outlined"
        aria-controls="interact-button"
        onClick={onConnect}
      >
        LET'S CONNECT
      </Button>
    </div>
  )
}
