import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { mailToStr } from './constants.js'

// region styles
const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: '#FFCC00',
    backgroundColor: '#313131',
    gap: '12px',
    height: '214px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingBottom: '12px',
  },
  buttonStyle: {
    maxWidth: 'fit-content',
    //maxHeight: '40px',
    borderThickness: '4px',
    marginTop: '24px',
  },
}))

export default function Welcome() {
  const classes = useStyles()

  const onConnect = () => {
    window.open(mailToStr)
  }

  return (
    <div className={classes.root} data-testid="welcome">
      <Typography color="secondary" style={{ fontFamily: ['Roboto'], fontSize: 36 }}>
        Hi, I'm Michelle!
      </Typography>
      <Typography style={{ color: '#FFFFFF', fontFamily: ['Roboto'], fontSize: 18 }}>
        Designer. Developer. Innovator.{' '}
      </Typography>{' '}
      <div />
      <Button
        className={classes.buttonStyle}
        color="secondary"
        size="large"
        variant="outlined"
        aria-controls="interact-button"
        onClick={onConnect}
      >
        LET'S CONNECT
      </Button>
    </div>
  )
}
