import { createTheme } from '@mui/material/styles'



export const lightTheme = createTheme({
  mixins: { toolbar: { minHeight: 100 } },
  palette: {
    type: 'light',
    primary: {
      main: '#992d82',
    },
    secondary: {
      main: '#f54fd1',
    },
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.55)',
    disabled: 'rgba(0, 0, 0, 0.55)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
})
