import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'

// region styles
const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: '#313131',
    height: '120px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '4px',
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <div className={classes.root} data-testid="footer">
      <Typography >© She Is Digital 2022 | michelle@sheisdigital.com</Typography>
    </div>
  )
}
